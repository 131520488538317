
import { Options, Vue } from 'vue-class-component';
import { QuestionAndAnswerClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class ExperienceFaq extends Vue {

    faqs: OM.QuestionAndAnswerVM[] = [];

    created() {
        QuestionAndAnswerClient.getExperienceFaq()
        .then(x => {
            this.faqs = x;
        })
    }

}
